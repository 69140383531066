@font-face {
    font-family: "IBMPlexMono";
    src: local("IBMPlexMono"),
    url("../assets/fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf") format("truetype");
    font-weight: normal;
}

* {
    font-family: IBMPlexMono, sans-serif;
}

.leaflet-control-attribution {
    display: none;
}

.leaflet-container {
    width: 100%;
    height: 100vh;
}

.marker-cluster {
    background-color: #8A8A8A !important;
}

.marker-cluster > div {
    background-color: #8A8A8A !important;
    color: #ffffff
}

.leaflet-interactive {
    transition: all 0ms !important;
}

.leaflet-control-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 400 !important;
    height: 84px;
    border-radius: 8px;
    top: 50%;
    left: 95%;
    transform: translate(-10%, -50%);
}

.leaflet-control {
    margin: 0 !important;
}

.leaflet-control-zoom-in {
    background-color: white !important;
    cursor: pointer;
    height: 42px !important;
    width: 35px !important;
}

.leaflet-control-zoom-in:hover {
    background-color: white !important;
}

.leaflet-control-zoom-out {
    background-color: white !important;
    cursor: pointer;
    height: 42px !important;
    width: 35px !important;
}

.leaflet-control-zoom-out:hover {
    background-color: white !important;
}

.leaflet-bar {
    border: none !important;

}

.leaflet-left {
    left: -450px !important;
}